.top-content-block {
    border-top: 1px solid rgba(45, 62, 86, 0.12);
    padding: 16px 0;
    margin-bottom: 21px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.collection-body {
    padding: 16px 0;
}

.collection-table {
    width: calc(100% - 40px);

    thead {
        td {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #222222;
            padding: 14px;
        }

        td:first-child {
            padding-left: 24px;
        }
    }

    tbody {
        td {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #696969;
            padding: 14px;
            vertical-align: middle;
        }

        p {
            color: #696969;
        }

        td:first-child {
            padding-left: 24px;
        }
    }

    &.music-collection {
        thead, tbody {
            td:nth-child(2) {
                width: 800px;
            }
        }
    }
}


.notification-table {
    width: 100%;

    thead {
        td {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #222222;
            padding: 14px;
        }

        td:first-child {
            padding-left: 24px;
        }
    }

    tbody {
        td {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #696969;
            padding: 14px;
            vertical-align: middle;
        }

        p {
            color: #696969;
        }

        td:first-child {
            padding-left: 24px;
            max-width: 200px;
        }

        td:nth-child(2) {
            max-width: 300px;
        }

        tr {
            &.empty-state {
                td {
                    height: 100px;
                    line-height: 100px;
                }
            }

            &:hover {
                background: #F7F9FC;
            }
        }
    }

    img {
        width: 40px;
        height: auto;
    }

    svg {
        fill: #2D3E56;
    }

    .notif-actions {
        display: flex;
        align-items: center;
        flex-direction: row;

        a, div {
            margin-right: 30px;
            cursor: pointer;
        }
    }
}

.empty-state {
    font-size: 18px;
    text-align: center;
    padding: 50px 0;
}

@media (max-width: 1760px) {
    .collection-table {
        &.music-collection {
            thead, tbody {
                td:nth-child(2) {
                    width: 600px;
                }
            }
        }
    }
}


@media (max-width: 1560px) {
    .collection-table {
        &.music-collection {
            thead, tbody {
                td:nth-child(2) {
                    width: 500px;
                }
            }
        }
    }
}
