.music-collection-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    margin: 0 14px 0 62px;
    border-bottom: 1px solid #C4C4C4;
    position: relative;

    .left-block {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .actions-block {
        text-align: left;
        white-space: nowrap;
    }

    .open-line-link {
        position: absolute;
        left: -38px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.collection-image {
    margin-right: 18px;
    background: #C4C4C4;
    border-radius: 8px;
}

.line-image {
    border-radius: 8px;
}

.music-collection-name-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: #222222;
    margin-bottom: 12px;
}

.music-collection-name {
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #696969;
    word-break: break-word;
    padding-right: 20px;
}

.actions-block {
    & > * {
        margin-right: 32px;
        display: inline-block;
        cursor: pointer;
    }
}

.chevron-icon {
    cursor: pointer;
    fill: #2D3E56;

    &:hover {
        fill: #03BAAE;
    }
}

.icon {
    fill: #000;

    &:hover {
        fill: #03BAAE;
    }
}

.collection-image-block {
    padding: 0 95px 0;
    .fourth-button {
        width: 150px;
        padding: 14px 16px 12px;
    }
}

.music-collection-1 {
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}

.music-collection {
    margin-left: 40px;
}


.collection-data-block {
    flex-grow: 2;
    padding: 0 12% 0 1%;
}

.files-block {
    display: flex;
    flex-direction: row;
    padding: 20px;
    flex-wrap: wrap;
    justify-content: center;
    .list-titles-line {
        width: 100%;
        min-height: 40px;
        margin: 6px 0;
        & *:first-child {
            width: 40px;
        }
        & > *:nth-child(3) {
            width: 50px;
        }
    }
    .list-track-line {
        width: 100%;
        min-height: 40px;
        margin: 6px 0;
        & *:first-child {
            width: 40px;
        }
        & > *:nth-child(3) {
            width: 50px;
            svg {
                cursor: pointer;
            }
        }
    }
}

.file-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 8px;
    .delete-file {
        position: absolute;
        top: -8px;
        left: 50%;
        margin-left: 10px;
        cursor: pointer;
    }
}

.file-name {
    font-size: 12px;
    color: #8A8A8F;
    display: inline-block;
    margin: 4px;
    word-break: break-word;
}

.track-files-block {
    display: flex;
    flex-direction: row;
    width: 180px;
}

.tracks-container {
    padding: 20px 0;
    min-height: 300px;
    position: relative;
}

.track-line {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    .actions-block {
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;

        svg {
            margin: 0;
        }
    }
}

.image-button-wrapper {
    position: relative;
    width: 95px;
    height: 95px;
    margin: 0 34px 0 20px;
    input {
        display: none;
    }
    span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 10px;
        bottom: 3px;
        color: red;
        width: 95px;
        text-align: center;
    }

    .track-image {
        position: absolute;
        width: 95px;
        height: auto;
        top: 0;
        border-radius: 13px;
        &.hide {
            display: none;
        }

    }

    .delete-image {
        position: absolute;
        right: -24px;
        top: 0;
        z-index: 10;
        cursor: pointer;
    }
}

.image-button {
    background: #F2FCFB;
    border: 1px solid #03BAAE;
    border-radius: 13px;
    width: 95px;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.track-name-block {
    flex-grow: 2;
    margin-right: 20px;
}

.track-actions-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #0A1F44;
    text-transform: uppercase;
    margin: 0 0 20px 0;
}

.list-titles-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;

    & > * {
        //display: inline-block;
        //vertical-align: middle;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;

        &:first-child {
            width: 80px;
        }

        &:nth-child(2) {
            width: 350px;
            flex-grow: 2;
            word-break: break-word;
            padding-right: 20px;
        }

        &:nth-child(3) {
            width: 150px;
        }

        &:nth-child(4) {
            width: 150px;
        }

        &:nth-child(5) {
            width: 150px;
        }

        &:nth-child(6) {
            width: 150px;
            text-align: center;

            svg:hover {
                fill: #03BAAE;
                cursor: pointer;
            }
        }

        &:nth-child(7) {
            width: 150px;
            text-align: center;

            svg:hover {
                fill: #03BAAE;
                cursor: pointer;
            }
        }
    }
}

.list-track-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 20px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin: 10px 0;
    background: #fff;
    min-height: 58px;

    & > * {
        font-size: 13px;

        &:first-child {
            width: 80px;
        }

        &:nth-child(2) {
            width: 350px;
            flex-grow: 2;
            word-break: break-word;
            padding-right: 20px;
        }

        &:nth-child(3) {
            width: 150px;
        }

        &:nth-child(4) {
            width: 150px;
        }

        &:nth-child(5) {
            width: 150px;
        }

        &:nth-child(6) {
            width: 150px;
            text-align: center;

            svg:hover {
                fill: #03BAAE;
                cursor: pointer;
            }
        }

        &:nth-child(7) {
            width: 150px;
            text-align: center;

            svg:hover {
                fill: #03BAAE;
                cursor: pointer;
            }
        }
    }

    .track-image {
        width: 48px;
        height: auto;
        border-radius: 13px;
    }
}

.tracks-blocker, .mp3-blocker {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 12;
}

.mp3-blocker {
    background: rgba(0, 0, 0, 0.6);
    p {
        color: #fff;
        width: 300px;
        text-align: center;
        margin: 50px auto;
        line-height: 1.4;
    }
    .first-button {
        width: 120px;
        margin: 0 auto;
    }
}

.loader-with-button {
    display: flex;
    align-items: center;
}

.delete-track-block {
    display: inline-block;
    margin: 0 auto;
}
