.steps-component-wrapper {
    border-bottom: 1px solid #C4C4C4;
    text-align: center;
}

.steps-component {
    display: inline-block;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
    position: relative;
    &:after {
        content: '';
        border-bottom: 1px solid #C4C4C4;
        position: absolute;
        width: 82%;
        left: 55px;
        top: 21px;
        z-index: 0;
    }
    &.two:after {
        width: 69%;
    }
}

.step-wrapper {
    display: inline-block;
    text-align: center;
    margin: 0 85px;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    &:first-child {
        margin: 0 85px 0 0;
    }
    &:last-child {
        margin: 0 0 0 85px;
    }
}

.step-circle {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background: #81DCD6;
    font-weight: 600;
    font-size: 18px;
    line-height: 43px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 16px;
    display: inline-block;
    &.active {
        background: #03BAAE;
    }

}

.step-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: #C4C4C4;

    &.active {
        color: #2D3E56;
    }
}
