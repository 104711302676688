.info-text {
    margin: 36px 0;
    color: #696969;
    line-height: 1.4;
}

.info-wrapper {
    background: #FFFFFF;
    border-radius: 13px;
    box-shadow: 0 4px 23px rgba(100, 140, 188, 0.2);
    padding: 26px 24px 14px;
    width: 100%;
}

.links-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.info-block {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    .input-block {
        width: 100%;
    }
    &:first-of-type {
        padding-right: 45px;
    }
}

.links-block {
    width: 100%;
}

.link-component {
    width: 48%;
    margin-bottom: 50px;
    .form-error {
       width: 80%;
    }
}

.info-form-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-right: 45px;
    .number {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #0A1F44;
        margin-bottom: 6px;
        text-transform: uppercase;
        margin-right: 8px;
    }
    .input-block {
        width: 100%;
    }
    .delete-link {
        cursor: pointer;
        &.hide {
            display: none;
        }
    }
}
