.input-block {
    margin-bottom: 10px;
    min-width: 350px;

    &.error {
        .input-error {
            display: flex;
            &.hide {
                display: none;
            }
        }
    }
}

.dates-block {
    display: flex;
    flex-direction: row;

    .input-select {
        width: 177px;
    }

    .input-block:nth-child(2) {
        .input-select {
            width: 218px;

        }
    }

    .input-block:nth-child(3) {
        .input-select {
            width: 156px;

        }
    }
}

.ages-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    p {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #0A1F44;
        margin-bottom: 6px;
        text-transform: uppercase;
        margin-right: 13px;

        &:nth-of-type(2) {
            margin-left: 20px;
        }
    }

    .input-block {
        width: 114px;
        margin-right: 4px;
    }
}

.input-label {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #0A1F44;
    margin-bottom: 6px;
    text-transform: uppercase;
}

.input {
    border: 1px solid #E1E4E8;
    border-radius: 4px;
    padding: 15px;
    color: #0A1F44;
    font-size: 14px;
    width: 100%;

    &.password {
        padding: 15px 35px 15px 15px !important;
    }

    &:focus {
        border-width: 2px;
        border-color: #03BAAE;
        border-style: solid;
        border-radius: 4px;
        padding: 14px;
    }

    &.error {
        border-width: 2px;
        border-color: #ED1566;
        border-style: solid;
        padding: 14px;
    }
}

.textarea {
    border: 1px solid #E1E4E8;
    border-radius: 4px;
    padding: 15px;
    color: #0A1F44;
    font-size: 14px;
    width: 100%;
    height: 140px;

    &.password {
        padding: 15px 35px 15px 15px;
    }

    &:focus {
        border-width: 2px;
        border-color: #03BAAE;
        border-style: solid;
        border-radius: 4px;
        padding: 14px;
    }

    &.error {
        border-width: 2px;
        border-color: #ED1566;
        border-style: solid;
        padding: 14px;
    }
}

.input-error {
    display: none;
    box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
    border-radius: 3px;
    padding: 8px 12px;
    align-items: center;

    span {
        color: #ED1566;
        margin-left: 10px;
        font-size: 12px;
    }

    &.hide {
        display: none !important;
    }

}

.form-error {
    box-shadow: 0 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
    border-radius: 3px;
    padding: 27px 24px;
    display: flex;
    align-items: center;
    margin: 10px auto;
    background: #fff;
    span {
        color: #000000;
        margin-left: 10px;
        font-size: 14px;
    }

    &.hide {
        display: none;
    }

}

.tracks-error {
    box-shadow: 0 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
    border-radius: 3px;
    padding: 27px 24px;
    display: flex;
    align-items: center;
    margin: 10px auto;
    justify-content: space-between;
    span {
        color: #000000;
        margin-left: 10px;
        font-size: 14px;
    }

    &.hide {
        display: none;
    }
}

td {
    position: relative;
}

.input-wrapper {
    display: block;
    position: relative;
}

.password-button {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    right: 12px;
}

.password-button1 {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -8px;
    right: 12px;
}

.input-search {
    background: #FFFFFF;
    box-shadow: 0 2px 15px rgba(100, 140, 188, 0.2);
    border-radius: 13px;
    padding: 13px 14px 14px 48px;
    width: 316px;
    font-size: 18px;
    line-height: 1.4;

    &::placeholder {
        color: #C0CBDE;
    }
}

.input-search-icon {
    position: absolute;
    left: 18px;
    top: 17px;
}

.input-select {
    & > div {
        border: 1px solid #E1E4E8;
        border-radius: 4px;
        padding: 5px 9px;
        color: #0A1F44;
        font-size: 14px;
        width: 100%;

        &:hover {
            border-color: #03BAAE;
            box-shadow: none;
        }
    }
}

.react-select__option {
    &:hover {
        background-color: red;
    }
}

.image-wrapper {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    margin: 0 0 15px;
}

.image-placeholder {
    background-color: #C4C4C4;
    width: 150px;
    height: 150px;
    text-align: center;
    padding: 50px 0;

    span {
        color: red;
        font-size: 12px;
    }
}

.big-image {
    width: 150px;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;

    &.hide {
        opacity: 0;
    }
}

.UploadBigImage {
    .files-button {
        display: block;
    }

    .attention {
        color: #8A8A8F;
        font-size: 14px;
        text-align: center;
        display: inline-block;
        margin-bottom: 20px;
    }
}

.notification-selects {
    & > .input-block:first-child {
        .input-select {
            width: 566px;
        }
    }

    & > .input-block:last-child {
        .input-select {
            width: 177px;
        }
    }

    .select-block {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    .first-button {
        width: 100px;
        padding: 14px 0 12px;
        font-size: 16px;
        margin: 0 0 10px 10px;
    }
}

.notification-block {
    padding: 0 63px;
}
