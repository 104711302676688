.notifications-tabs-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 32px;
}

.notif-tab {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    position: relative;
    margin-right: 40px;
    cursor: pointer;
    &.active {
        &:after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 2px;
            background: #03BAAE;
            border-radius: 2px;
        }
    }
}

.content-header {
    padding: 4px 0 30px;
    margin: 0 23px;
    border-bottom: 1px solid #C4C4C4;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notif-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #2D3E56;
}

.tabs-block {
    display: flex;
    align-items: center;
}

.tab {
    width: 172px;
    text-align: center;
    padding: 8px 0;
    border: 1px solid #03BAAE;
    color: #696969;
    cursor: pointer;
    &:first-child {
        border-radius: 4px 0 0 4px;
        transform: translateX(1px);
    }
    &:last-child {
        border-radius: 0 4px 4px 0;
    }
    &.active {
        color: #FFFFFF;
        background: #03BAAE;
    }
}
