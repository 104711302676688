body {
    background: #F7F9FC;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1366px;
    height: 100vh;
}

.login-wrapper {
    background: #FFFFFF;
    box-shadow: 0px 4px 23px rgba(100, 140, 188, 0.29);
    border-radius: 13px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.main-container {
    min-height: 100vh;
    padding: 0 0 0 0;
    min-width: 1366px;
    display: flex;
    flex-direction: row;
}

.page-container {
    flex-grow: 2;
    padding: 20px 40px;
    margin-left: 347px;
}

.content-block {
    background: #FFFFFF;
    border-radius: 13px;
    box-shadow: 0px 4px 23px rgba(100, 140, 188, 0.2);
    padding: 26px 24px 14px;

    &.import-page {
        min-height: 500px;
    }

    &.notifications {
        padding: 26px 0 14px;
    }

    &.notifications-block {
        padding: 26px 62px 14px;
    }
}

.user-profile-block {
    background: #FFFFFF;
    border-radius: 13px;
    box-shadow: 0px 4px 23px rgba(100, 140, 188, 0.2);
    padding: 26px 24px 14px;
    width: 630px;
}

.user-profile-line {
    border-top: 1px solid #C4C4C4;
    margin: 20px 0 30px;
}

.notifications-block {
    .modal-button-block button {
        width: 200px;
        display: inline-block;
        margin: 4px 0;
    }
}

.content-footer {
    padding: 24px 0;
    border-top: 1px solid rgba(196, 196, 196, 0.5);
    display: flex;
    justify-content: flex-end;
    align-self: stretch;

    &.two-buttons {
        justify-content: space-between;
    }
}
