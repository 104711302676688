.collection-block {
    background: #FFFFFF;
    box-shadow: 0 4px 23px rgba(100, 140, 188, 0.2);
    border-radius: 13px;
    margin: 8px 0;
    &.closed {
        .collection-body, .collection-footer {
            display: none;
        }
        .students-collection-header, .music-collection-header {
            border: 0;
        }
    }
}

.students-collection-header {
    padding: 15px 0;
    margin: 0 24px;
    border-bottom: 1px solid #C4C4C4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            font-weight: 600;
            font-size: 12px;
            margin-left: 10px;
        }
    }
}

.name-line {
    display: flex;
    align-items: center;
}

.collection-name-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: #222222;
    padding-left: 41px;
    margin-bottom: 6px;
}

.collection-name {
    padding-left: 18px;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #696969;
}

.collection-music-footer {
    border-top: 1px solid #C4C4C4;
    padding: 24px 0;
    margin: 0 24px;
    display: flex;
    justify-content: flex-end;
    &.two-buttons {
        justify-content: space-between;
    }
    .music-collection-name {
        margin: 8px 0;
    }
}


.collection-footer {
    border-top: 1px solid #C4C4C4;
    padding: 24px 0;
    margin: 0 24px 0 62px;
    display: flex;
    justify-content: flex-end;
    &.two-buttons {
        justify-content: space-between;
    }
    .music-collection-name {
        margin: 8px 0;
    }
}


.collections-footer {
    padding: 24px 0;
    margin: 0 24px;
    display: flex;
    justify-content: flex-end;
    &.two-buttons {
        justify-content: space-between;
    }
    .music-collection-name {
        margin: 8px 0;
    }
}
.open-line-link {
    cursor: pointer;
    padding: 5px 10px 7px;
    margin-left: -7px;
}
