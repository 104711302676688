.pagination-line {
    display: flex;
    align-items: center;
    margin-right: -8px;
}

.pagination-button {
    background: rgba(163, 163, 163, 0.2);
    border-radius: 4px;
    width: 24px;
    height: 24px;
    text-align: center;
    padding: 0;
    margin: 0 8px;
    border: 0;
    cursor: pointer;
}

.pagination-number {
    cursor: pointer;
}

.pagination-number, .pagination-link {
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    background: rgba(244, 226, 253, 0.29);
    border-radius: 4px;
    border: 0;
    width: 24px;
    height: 24px;
    text-align: center;
    padding: 0;
    margin: 0 8px;
    &.active {
        background: #F4E2FD;
    }
}

.pagination-link {
   line-height: 24px;
}
