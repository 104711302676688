.nav-bar {
    width: 347px;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    padding: 26px 0;
    height: 100vh;
    background: #FFFFFF;
    box-shadow: 0 4px 23px rgba(100, 140, 188, 0.1);
    .modal-button-block {
        button {
            width: 200px
        }
    }

    & > div {
        cursor: pointer;
    }
}

.nav-bar-image-link {
    margin: 0 112px 34px;
    display: inline-block;
    text-align: center;
}
.nav-bar-image {
    width: 123px;
    height: auto;
}

.nav-bar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 0 16px 46px;
    &.active {
        background: #CFF2EF;
    }
}

.back-for-icon {
    background: #03BAAE;
    border-radius: 4px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}
