.content-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  text-align: center;
}

.files-button {
  margin-bottom: 1rem;
  display: inline-block;

  input {
    display: none;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  &.notification-selects {
    align-items: flex-start;
    padding: 24px 0;
    .input-block {
      min-width: auto;
    }
    .input-select {
      margin-right: 8px;
      max-width: 800px;
    }
  }
  &.with-files {
    justify-content: flex-start;
    padding-top: 20px;
  }
}

.import-upload {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.files-area {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.file-wrapper {
  margin: 0 10px;
}

.success-text {
  font-size: 16px;
  margin-bottom: 20px;
}

.success-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;

  .content-title {
    margin-bottom: 20px;
  }
}
