@import 'styles/reset';
@import 'styles/texts';
@import 'styles/colors';
@import 'styles/test';
@import 'styles/pagination';
@import 'styles/containers';
@import 'styles/inputs';
@import 'styles/buttons';
@import 'styles/navbar';
@import 'styles/topBar';
@import 'styles/pageTitle';
@import 'styles/importData';
@import 'styles/table';
@import 'styles/students';
@import 'styles/musicManagement';
@import 'styles/notificationsManagement';
@import 'styles/modal';
@import 'styles/stepsComponent';
@import 'styles/errorPage';
@import 'styles/infoPage';

.trimmed-row {
  text-overflow: ellipsis;
  overflow: hidden;
}
