button {

}

.first-button {
    background: #03BAAE;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    border: 1px solid #03BAAE;
    padding: 14px 24px 12px;
    cursor: pointer;

    &:hover {
        background: rgba(3, 186, 174, 0.05);
        color: #03BAAE;
        border: 1px solid #03BAAE;
    }

    &:disabled {
        background: #E1E4E8;
        color: #A6AEBC;
        border: 1px solid #C9CED6;
    }

    &.disabled {
        background: #E1E4E8;
        color: #A6AEBC;
        border: 1px solid #C9CED6;
        pointer-events: none;
    }
}

.goBack-button {
    cursor: pointer;
    background: #2D3E56;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    margin-right: 28px;
    border: 2px solid #2D3E56;
    padding: 0;

    &:hover {
        background: rgba(196, 196, 196, 0.41);

        svg {
            fill: #2D3E56;
        }
    }

    svg {
        fill: #fff;
    }
}

.second-button {
    background: #2D3E56;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    border: 1px solid #2D3E56;
    padding: 15px;
    cursor: pointer;

    &:hover {
        background: rgba(196, 196, 196, 0.41);
        color: #2D3E56;
        border: 1px solid #2D3E56;
    }

    &:disabled {
        background: #E1E4E8;
        color: #A6AEBC;
        border: 1px solid #C9CED6;
    }
}

.top-content-block {
    .second-button {
        padding: 15px 60px 15px;
    }
}

.UploadBigImage {
    width: 150px
}

.third-button {
    background: transparent;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #03BAAE;
    border: 1px solid #03BAAE;
    padding: 14px 24px 12px;
    cursor: pointer;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);

    &:hover {
        background: rgba(3, 186, 174, 0.05);
        color: #03BAAE;
        border: 1px solid #03BAAE;
    }

    &:disabled {
        background: #E1E4E8;
        color: #A6AEBC;
        border: 1px solid #C9CED6;
    }
}

.fourth-button {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #2D3E56;
    padding: 14px 24px 12px;
    cursor: pointer;

    &:hover {
        color: #03BAAE;
    }

    &:disabled {
        color: #A6AEBC;
    }
}

.logout-buttton {
    cursor: pointer;
}

.loader {
    position: relative;
    width: 48px;
    height: 48px;
    display: block;
    margin: 0 auto;
}
