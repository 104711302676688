.modal-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 15;
}

.modal-block {
    background: #fff;
    position: relative;
    padding: 25px 29px 42px;
    box-shadow: 0 16px 32px rgba(45, 62, 86, 0.04);
    border-radius: 13px;
    width: 300px;
}

.modal-block-image {
    background: #fff;
    position: relative;
    padding: 25px 29px 42px;
    box-shadow: 0 16px 32px rgba(45, 62, 86, 0.04);
    border-radius: 13px;
    width: 600px;
}

.modal-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #2D3E56;
    margin-bottom: 11px;
}

.modal-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: rgba(70, 70, 70, 0.4);
    margin-bottom: 22px;
}

.import-page {
    .modal-button-block button {
        width: 200px;
        display: inline-block;
        margin: 4px 0;
    }
}
.modal-button-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button {
        width: 100px;
        display: inline-block;
        margin: 4px 0;
    }
}

.close-modal {
    position: absolute;
    top: 17px;
    right: 17px;
    cursor: pointer;
    svg {
        fill: rgba(199, 199, 204, 0.8);
    }

    &:hover {
        svg {
            fill: #03BAAE;
        }
    }
}
