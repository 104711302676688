.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(247,249,252,1) 50%);
}

.error-image-link {
    cursor: pointer;
}

.error-number {
    font-weight: 800;
    font-size: 144px;
    color: #535353;
    margin-bottom: 10px;
}

.error-title {
    font-weight: 800;
    font-size: 24px;
    line-height: 18px;
    text-transform: uppercase;
    color: #535353;
    margin-bottom: 40px;
}

.error-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #535353;
    margin-bottom: 30px;
}
