.top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0 20px 30px;
    border-radius: 15px 0 0 15px;
    position: relative;
    &.active {
        background: #CFF2EF;
        &:after {
            content: '';
            position: absolute;
            right: -40px;
            top: 0;
            width: 40px;
            height: 80px;
            background: #CFF2EF;
        }
    }

    .modal-button-block {
        button {
            width: 200px;
        }
    }
}

.block-like-icon {
    background: #C4C4C4;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    margin-right: 16px;
}

.user-name-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 60px;
    cursor: pointer;
    span {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: #2D3E56;
    }
}

.logout-button {
    cursor: pointer;
}
