.page-title-block {
    padding-bottom: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-title-wrapper {
    display: flex;
    align-items: center;
}

.page-title {
    font-weight: bold;
    font-size: 32px;
    line-height: 1.4;
    color: #2D3E56;
}
